import React, {useState, useEffect, lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './assets/libs/all.min.css';
import { store, INIT } from "./api/Core"

const App = lazy(() => import('./components/App'));




const Preloader = props => {
    const [load, setLoad] = useState(true);
    useEffect(() => {
        INIT().then(() => setLoad(false))
    }, [])
    return load ? <div className="Preloader" style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0, background: "#1b1c21", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "30px", letterSpacing: "1px", color: "white", fontFamily: "sans-serif"}}>ЗАГРУЗКА</div> : <Provider store={store}><Suspense fallback={<div className="Preloader" style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0, background: "#1b1c21", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "30px", letterSpacing: "1px", color: "white", fontFamily: "sans-serif"}}>ЗАГРУЗКА</div>}><App /></Suspense></Provider>
}

ReactDOM.render(<Preloader />, document.getElementById('root'));