import { combineReducers } from 'redux';
import popup from './popup-reducer'
import user from './user-reducer'
import appState from './app-state-reducer'

const rootReducer = combineReducers({
    popup,
    user,
    appState: appState,
})

export default rootReducer;