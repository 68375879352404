export default function (state = {
    loaded: false,
    mainSlider: [],
    searchOpened: false,
    bundles: [],
    products: []
}, action) {
    switch (action.type) {
        case "INIT":
            return {...state, loaded: true, products: action.payload.products, banners: action.payload.banners, bundles: action.payload.bundles}
        default:
            return state
    }

}