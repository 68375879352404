import { createStore } from 'redux';
import root from '../reducers/index';

const store = createStore(root)

const AD = (type, payload) => {
  store.dispatch({type, payload})
}

const delay = (ms) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  })
)

const callAPI = (target, body = {}) => fetch(target, {
  method: 'post',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
}).then(res => {
  return res.json();
}).catch(err => {
  console.log(err)
});

const INIT = () => {
  return callAPI("/").then(data => {
    console.log("INIT DATA", data)
    if(data) AD("INIT", data)
  })

}


const User = {
  logout: () => callAPI("/logout").then(res => {
    res.success && AD("USER_LOGOUT")
    return res
  }),
  login: data => {
    data.success && AD("USER_LOGIN", data.user)
    return data
  },
  testSubscribe: () => callAPI("/testsubscribe").then(res => {
    res.success && AD("USER_CHANGE", res.user)
    return res
  }),
}

const Popup = {
  open:  (payload) => AD("POPUP_OPEN", payload),
  close: () => AD("POPUP_CLOSE"),
  flip:  (payload) => {
    AD("POPUP_FLIP")
    delay(200).then(() => AD("POPUP_OPEN", payload))
  },
  alert: (statusType) => {
    AD("POPUP_FLIP")
    delay(200).then(() => AD("POPUP_OPEN", {type: "statusMessage", opts: {statusType}}))
    delay(3500).then(() => AD("POPUP_CLOSE")
    )
  }
}


export {
  store,
  INIT,
  callAPI,
  Popup,
  User,
}