const initial = {
    username:  null,
    id:        null,
    balance:   null,
    isGuest:   null
}

export default function (state = {...initial, loaded: false}, action) {
    switch (action.type) {
        case 'USER_LOGIN':
            return {...state, ...action.payload, isGuest: false}
        case 'USER_LOGOUT':
            return null
        case 'USER_CHANGE':
            return action.payload
        case 'INIT':
            return action.payload.user
        default:
            return state
    }

}