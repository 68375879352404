export default function (state = {state: false, type: null, flipped: false, opts: {}}, action) {
    switch (action.type) {
        case 'POPUP_CLOSE':
            return {
                ...state,
                state: false,
            }

        case 'POPUP_OPEN':
            return {
                ...state,
                state: true,
                type:  action.payload.type,
                opts: action.payload.opts || {}
            }
        case 'POPUP_FLIP':
            return {
                ...state,
                flipped: !state.flipped,
            }
        default:
            return state
    }

}